* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: system, -apple-system, -webkit-system-font, SFNSText, "Segoe UI",
    "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
}

html {
  font-size: calc((1vw + 1vh) / 2);
}

html,
body,
#root,
#anchor {
  height: 100%;
}

figure {
  text-align: center;
  transform-style: preserve-3d;
  transform: translateZ(-200px);
  backface-visibility: hidden;
}

main {
  height: 100%;
  display: flex;
  perspective: 100rem;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  padding: 5rem;
}

section {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 2.5rem;
  display: flex;
  justify-content: space-between;
}

a {
  text-decoration: none;
  font-size: 2.25rem;
  font-weight: 400;
}

@media only screen and (max-width: 600px) {
  a {
    font-size: 2.5rem;
  }
}

a:hover {
  text-decoration: line-through;
}